

























































































































































































































































import DialogMixins from '@/mixins/DialogMixins';
import WidgetMixins from '@/mixins/WidgetMixins';
import { ILogs, IPaginate } from '@/types/types';
import { loadWidget } from '@/utils/helpers';
import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import moment from 'moment';
interface ITabs {
  text: string;
  value: string;
}
const logsModule = namespace('log');

@Component({
  name: 'Logs',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
  },
  filters: {
    timestamp(value: string) {
      return moment(value).format('Do MMMM, YYYY');
    },
  },
})
export default class Logs extends mixins(WidgetMixins, DialogMixins) {
  @Getter('getCreditBalance') creditBalance!: number;
  @logsModule.Getter('getAllLogs') logs!: ILogs[];
  @logsModule.Getter('getLogDetails') details!: ILogs;
  @logsModule.Getter('getPagination') paginate!: IPaginate & { limit: number };
  tab = 0;
  items: ITabs[] = [
    { text: 'All', value: 'all' },
    { text: 'Succeeded', value: 'success' },
    { text: 'Failed', value: 'failed' },
    { text: 'Pending', value: 'pending' },
  ];
  activeItemId = 0;
  query = '';

  @Watch('tab')
  onTabChange(tab: number): void {
    const active = this.items[tab];
    this.query = `&status=${active.value}`;
    this.$store.dispatch('log/list', {
      page: 1,
      limit: this.paginate.limit,
      query: active.value === 'all' ? '' : this.query,
    });
  }

  loadMore(): void {
    this.$store.dispatch('log/loadMore', {
      ...this.paginate,
      query: this.query,
    });
  }

  viewDetails(id: number): void {
    this.activeItemId = id;
    this.$store.dispatch('log/details', id);
  }

  created(): void {
    this.$store.dispatch('log/list', { page: 1, limit: 20 });
  }
}
